"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Activities - Activities API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RmaReturnReasonEnum = void 0;
exports.instanceOfRma = instanceOfRma;
exports.RmaFromJSON = RmaFromJSON;
exports.RmaFromJSONTyped = RmaFromJSONTyped;
exports.RmaToJSON = RmaToJSON;
const runtime_1 = require("../runtime");
/**
 * @export
 */
exports.RmaReturnReasonEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
};
/**
 * Check if a given object implements the Rma interface.
 */
function instanceOfRma(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "part" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "machineNumber" in value;
    isInstance = isInstance && "returnReason" in value;
    isInstance = isInstance && "isInReturnProcess" in value;
    isInstance = isInstance && "trackingNumber" in value;
    return isInstance;
}
function RmaFromJSON(json) {
    return RmaFromJSONTyped(json, false);
}
function RmaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'orderNumber': !(0, runtime_1.exists)(json, 'orderNumber') ? undefined : json['orderNumber'],
        'part': json['part'],
        'designation': !(0, runtime_1.exists)(json, 'designation') ? undefined : json['designation'],
        'replacementOrderNumber': !(0, runtime_1.exists)(json, 'replacementOrderNumber') ? undefined : json['replacementOrderNumber'],
        'replacementOrderDate': !(0, runtime_1.exists)(json, 'replacementOrderDate') ? undefined : (new Date(json['replacementOrderDate'])),
        'returnOrderNumber': !(0, runtime_1.exists)(json, 'returnOrderNumber') ? undefined : json['returnOrderNumber'],
        'returnOrderDate': !(0, runtime_1.exists)(json, 'returnOrderDate') ? undefined : (new Date(json['returnOrderDate'])),
        'serialNumber': json['serialNumber'],
        'amount': json['amount'],
        'machineNumber': json['machineNumber'],
        'returnReason': json['returnReason'],
        'returnReasonOther': !(0, runtime_1.exists)(json, 'returnReasonOther') ? undefined : json['returnReasonOther'],
        'isInReturnProcess': json['isInReturnProcess'],
        'trackingNumber': json['trackingNumber'],
    };
}
function RmaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'orderNumber': value.orderNumber,
        'part': value.part,
        'designation': value.designation,
        'replacementOrderNumber': value.replacementOrderNumber,
        'replacementOrderDate': value.replacementOrderDate === undefined ? undefined : (value.replacementOrderDate.toISOString()),
        'returnOrderNumber': value.returnOrderNumber,
        'returnOrderDate': value.returnOrderDate === undefined ? undefined : (value.returnOrderDate.toISOString()),
        'serialNumber': value.serialNumber,
        'amount': value.amount,
        'machineNumber': value.machineNumber,
        'returnReason': value.returnReason,
        'returnReasonOther': value.returnReasonOther,
        'isInReturnProcess': value.isInReturnProcess,
        'trackingNumber': value.trackingNumber,
    };
}
