"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ZKP Shop - Shop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MassiveCompanyApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class MassiveCompanyApi extends runtime.BaseAPI {
    /**
     * Returns some meta information about the company.
     */
    async readMassiveCompanyRaw(requestParameters, initOverrides) {
        if (requestParameters.externalId === null || requestParameters.externalId === undefined) {
            throw new runtime.RequiredError('externalId', 'Required parameter requestParameters.externalId was null or undefined when calling readMassiveCompany.');
        }
        if (requestParameters.formType === null || requestParameters.formType === undefined) {
            throw new runtime.RequiredError('formType', 'Required parameter requestParameters.formType was null or undefined when calling readMassiveCompany.');
        }
        if (requestParameters.orderChecklistNumber === null || requestParameters.orderChecklistNumber === undefined) {
            throw new runtime.RequiredError('orderChecklistNumber', 'Required parameter requestParameters.orderChecklistNumber was null or undefined when calling readMassiveCompany.');
        }
        if (requestParameters.hash === null || requestParameters.hash === undefined) {
            throw new runtime.RequiredError('hash', 'Required parameter requestParameters.hash was null or undefined when calling readMassiveCompany.');
        }
        const queryParameters = {};
        if (requestParameters.formType !== undefined) {
            queryParameters['formType'] = requestParameters.formType;
        }
        if (requestParameters.orderChecklistNumber !== undefined) {
            queryParameters['orderChecklistNumber'] = requestParameters.orderChecklistNumber;
        }
        if (requestParameters.hash !== undefined) {
            queryParameters['hash'] = requestParameters.hash;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/massive/v1/company/{externalId}`.replace(`{${"externalId"}}`, encodeURIComponent(String(requestParameters.externalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.MassiveCompanyFromJSON)(jsonValue));
    }
    /**
     * Returns some meta information about the company.
     */
    async readMassiveCompany(requestParameters, initOverrides) {
        const response = await this.readMassiveCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Lists the companies assign to the current user.
     */
    async readMassiveCompanyListRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/store-api/massive/v1/company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.MassiveCompanyFromJSON));
    }
    /**
     * Lists the companies assign to the current user.
     */
    async readMassiveCompanyList(initOverrides) {
        const response = await this.readMassiveCompanyListRaw(initOverrides);
        return await response.value();
    }
    /**
     * Update massive company data from current user
     */
    async updateMassiveCompanyRaw(requestParameters, initOverrides) {
        if (requestParameters.massiveCompanyPatchRequestBody === null || requestParameters.massiveCompanyPatchRequestBody === undefined) {
            throw new runtime.RequiredError('massiveCompanyPatchRequestBody', 'Required parameter requestParameters.massiveCompanyPatchRequestBody was null or undefined when calling updateMassiveCompany.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/massive/v1/company`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.MassiveCompanyPatchRequestBodyToJSON)(requestParameters.massiveCompanyPatchRequestBody),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update massive company data from current user
     */
    async updateMassiveCompany(requestParameters, initOverrides) {
        await this.updateMassiveCompanyRaw(requestParameters, initOverrides);
    }
    /**
     */
    async validateCompanyDataRaw(requestParameters, initOverrides) {
        if (requestParameters.massiveCompanyValidateRequestBody === null || requestParameters.massiveCompanyValidateRequestBody === undefined) {
            throw new runtime.RequiredError('massiveCompanyValidateRequestBody', 'Required parameter requestParameters.massiveCompanyValidateRequestBody was null or undefined when calling validateCompanyData.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/massive/v1/_action/company/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.MassiveCompanyValidateRequestBodyToJSON)(requestParameters.massiveCompanyValidateRequestBody),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.MassiveCompanyValidateResultFromJSON)(jsonValue));
    }
    /**
     */
    async validateCompanyData(requestParameters, initOverrides) {
        const response = await this.validateCompanyDataRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async validateTaxIdRaw(requestParameters, initOverrides) {
        if (requestParameters.taxIdValidation === null || requestParameters.taxIdValidation === undefined) {
            throw new runtime.RequiredError('taxIdValidation', 'Required parameter requestParameters.taxIdValidation was null or undefined when calling validateTaxId.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/store-api/massive/v1/_action/vat/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: (0, models_1.TaxIdValidationToJSON)(requestParameters.taxIdValidation),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async validateTaxId(requestParameters, initOverrides) {
        await this.validateTaxIdRaw(requestParameters, initOverrides);
    }
}
exports.MassiveCompanyApi = MassiveCompanyApi;
