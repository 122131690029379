"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AuthorizationScopes;
(function (AuthorizationScopes) {
    AuthorizationScopes["ACCOUNT_CREATE"] = "account:create";
    AuthorizationScopes["ACCOUNT_DELETE"] = "account:delete";
    AuthorizationScopes["ACCOUNT_READ"] = "account:read";
    AuthorizationScopes["ACCOUNT_UPDATE"] = "account:update";
    AuthorizationScopes["ADDRESS_CREATE"] = "address:create";
    AuthorizationScopes["ADDRESS_DELETE"] = "address:delete";
    AuthorizationScopes["ADDRESS_READ"] = "address:read";
    AuthorizationScopes["ADDRESS_UPDATE"] = "address:update";
    AuthorizationScopes["CART_READ"] = "cart:read";
    AuthorizationScopes["CART_UPDATE"] = "cart:update";
    AuthorizationScopes["CHANGE_CREATE"] = "change:create";
    AuthorizationScopes["COMPANY_READ"] = "company:read";
    AuthorizationScopes["COMPANY_UPDATE"] = "company:update";
    AuthorizationScopes["CONNECT_READ"] = "connect:read";
    AuthorizationScopes["DASHBOARD"] = "dashboard";
    AuthorizationScopes["DELEGATE"] = "delegate";
    AuthorizationScopes["DOCUMENTS_READ"] = "documents:read";
    AuthorizationScopes["DOWNLOADS_READ"] = "downloads:read";
    AuthorizationScopes["LICENSE_ACTIVATE"] = "license:activate";
    AuthorizationScopes["LICENSE_DOWNLOAD"] = "license:download";
    AuthorizationScopes["LICENSE_READ"] = "license:read";
    AuthorizationScopes["LICENSE_UPDATE"] = "license:update";
    AuthorizationScopes["MACHINE_READ"] = "machine:read";
    AuthorizationScopes["NEWS_READ"] = "news:read";
    AuthorizationScopes["OFFER_READ"] = "offer:read";
    AuthorizationScopes["ORDER_CREATE"] = "order:create";
    AuthorizationScopes["ORDER_READ"] = "order:read";
    AuthorizationScopes["PROFILE_READ"] = "profile:read";
    AuthorizationScopes["PROFILE_UPDATE"] = "profile:update";
    AuthorizationScopes["REGISTRATION_CREATE"] = "registration:create";
    AuthorizationScopes["RESELLER_DOCUMENTS_READ"] = "reseller_documents:read";
    AuthorizationScopes["RESELLER_READ"] = "reseller:read";
    AuthorizationScopes["RETURN_READ"] = "return:read";
    AuthorizationScopes["RETURN_REASON_UPDATE"] = "return_reason:update";
    AuthorizationScopes["RETURN_UPDATE"] = "return:update";
    AuthorizationScopes["SERVICE_DELETE"] = "service:delete";
    AuthorizationScopes["SERVICE_READ"] = "service:read";
    AuthorizationScopes["SHOP_READ"] = "shop:read";
    AuthorizationScopes["SOFTWARE_READ"] = "software:read";
})(AuthorizationScopes || (AuthorizationScopes = {}));
exports.default = AuthorizationScopes;
