"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenRoutes = void 0;
const flattenRoutes = (routes, includeParentRoutes) => routes.reduce((flattenedRoutes, route) => {
    const hasChildren = (route.routes?.length ?? 0) > 0;
    return [
        ...flattenedRoutes,
        ...(includeParentRoutes || !hasChildren
            ? [
                {
                    ...route,
                    routes: undefined,
                },
            ]
            : []),
        ...(0, exports.flattenRoutes)(route.routes ?? [], includeParentRoutes),
    ];
}, []);
exports.flattenRoutes = flattenRoutes;
