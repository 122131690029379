"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopicPilotLanguageMap = void 0;
exports.TopicPilotLanguageMap = {
    de: 'de_DE',
    en: 'en_US',
    es: 'es_ES',
    fr: 'fr_FR',
    it: 'it_IT',
    nl: 'nl_NL',
};
