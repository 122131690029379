"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_router_dom_1 = require("react-router-dom");
const useHelpUrl = () => {
    const { t } = (0, react_i18next_1.useTranslation)();
    const { pathname } = (0, react_router_dom_1.useLocation)();
    return (0, react_1.useMemo)(() => {
        const translationArguments = pathname
            .replace(/^\//, '')
            .replace(/[-.]/g, '_')
            .split('/')
            .reduce((result, part, index) => ({
            [`path${index + 1}`]: '',
            ...result,
            [`path${index}`]: part,
        }), {});
        return t('help.url', translationArguments).trim();
    }, [pathname, t]);
};
exports.default = useHelpUrl;
